import "./job-application-target.css";

let applyClicks = document.getElementsByClassName('apply-click');
for (let i=0; i<applyClicks.length; i++){
    applyClicks[i].addEventListener('click', function(event) {
        const appUrl = document.getElementById('app_url').value;
        const jobId = document.getElementById('job_id').value;
        let formData = new FormData();
        formData.append(getMetaContent('csrf-param'), getMetaContent('csrf-token'));
        let xhr = new XMLHttpRequest();
        xhr.open("POST", appUrl + '/jobs/write_apply_clicking?job_id=' + jobId);
        xhr.send(formData);
    });
}

function getMetaContent(metaName) {
    let meta = document.head.children;
    let metaLength = meta.length;
    let result = null;
    for (let i = 0; i < metaLength; i++){
        let proper = meta[i].getAttribute('name');
        if(proper === metaName) {
            result = meta[i].getAttribute('content');
            break;
        }
    }
    return result;
}