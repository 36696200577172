import "./user-experience-edit.css";

let buttonAddUserExperienceConfirm = document.getElementById('confirm-add-user-experience');
if (buttonAddUserExperienceConfirm) {
    buttonAddUserExperienceConfirm.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        // AJAX
        let formAddUserExperience = document.getElementById('add-user-experience-form');
        formAddUserExperience.submit();
    });
}