import "./user-profile.css";

let buttonUserProfileConfirm = document.getElementById('users-profile-confirm');
if (buttonUserProfileConfirm) {
    buttonUserProfileConfirm.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        let formUserProfile = document.getElementById('users-profile-form');
        formUserProfile.submit();
    });
}