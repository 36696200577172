import "./orgs-new.css";

let buttonOrgsConfirm = document.getElementById('orgs-confirm');
if (buttonOrgsConfirm) {
    buttonOrgsConfirm.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        let formJobsNew = document.getElementById('orgs-form');
        formJobsNew.submit();
    });
}