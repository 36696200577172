import "./job-checkout.css";

let buttonNavigatorPreview = document.getElementById('navigator-preview');
if (buttonNavigatorPreview) {
    buttonNavigatorPreview.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        if (location.search) {
            location.href = '/jobs/preview' + location.search;
        } else {
            let jobId = document.getElementById('job_id').value;
            if (jobId) {
                location.href = '/jobs/preview?id=' + jobId;
            }
        }
    });
}