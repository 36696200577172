import "./jobs-new.css";

let buttonNavigatorOrg = document.getElementById('navigator-org');
if (buttonNavigatorOrg) {
    buttonNavigatorOrg.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        location.href = '/orgs/new';
    });
}

let buttonJobsConfirm = document.getElementById('jobs-confirm');
if (buttonJobsConfirm) {
    buttonJobsConfirm.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        let formJobsNew = document.getElementById('jobs-form');
        formJobsNew.submit();
    });
}
