import "./job-checkout-detail.css";

const stripePk = document.getElementById('stripe_pk');
if (stripePk) {
    const stripe = Stripe(stripePk.value);
    const stripeSKUs = document.getElementById('stripe_skus').value.split(",");
    for (let stripeSKU in stripeSKUs) {
        (function (i) {
            const appUrl = document.getElementById('app_url').value;
            let checkoutButton = document.getElementById('checkout-button-' + stripeSKUs[i]);
            if (checkoutButton) {
                const isAppliedCoupon = document.getElementById('is_applied_coupon').value;
                console.log("😺isAppliedCoupon:", isAppliedCoupon);
                if (isAppliedCoupon === "true") {
                    checkoutButton.addEventListener('click', function () {
                        // Without Stripe
                        freeCheckout(stripeSKUs[i]);
                    });
                } else {
                    checkoutButton.addEventListener('click', function () {
                        // With Stripe
                        history.pushState(null, null, null);
                        stripe.redirectToCheckout({
                            items: [{
                                sku: stripeSKUs[i],
                                quantity: 1
                            }],
                            successUrl: appUrl + '/users/jobs',
                            cancelUrl: appUrl + '/users/jobs',
                            customerEmail: document.getElementById('email').value,
                            clientReferenceId: document.getElementById('job_id').value,
                        })
                            .then(function (result) {
                                if (result.error) {
                                    var displayError = document.getElementById('error-message');
                                    displayError.textContent = result.error.message;
                                }
                            });
                    });
                }
            }
        }(stripeSKU));
    }
}

let checkoutButtonFree = document.getElementById('checkout-button-free');
if (checkoutButtonFree) {
    checkoutButtonFree.addEventListener('click', function () {
        freeCheckout('free');
    });
}

function freeCheckout(plan) {
    history.pushState(null, null, null);
    const appUrl = document.getElementById('app_url').value;
    let form = document.createElement('form');
    let authenticityTokenElement = document.createElement('input');
    let clientReferenceIdElement = document.createElement('input');
    let planElement = document.createElement('input');
    form.method = 'POST';
    form.action = appUrl + '/jobs/checkout_free';
    authenticityTokenElement.type = 'hidden';
    authenticityTokenElement.name = getMetaContent('csrf-param');
    authenticityTokenElement.value = getMetaContent('csrf-token');
    form.appendChild(authenticityTokenElement);
    clientReferenceIdElement.type = 'hidden';
    clientReferenceIdElement.name = 'client_reference_id';
    clientReferenceIdElement.value = document.getElementById('job_id').value;
    form.appendChild(clientReferenceIdElement);
    planElement.type = 'hidden';
    planElement.name = 'plan';
    planElement.value = plan;
    form.appendChild(planElement);
    document.body.appendChild(form);
    form.submit();
}

function getMetaContent(metaName) {
    let meta = document.head.children;
    let metaLength = meta.length;
    let result = null;
    for (let i = 0; i < metaLength; i++){
        let proper = meta[i].getAttribute('name');
        if(proper === metaName) {
            result = meta[i].getAttribute('content');
            break;
        }
    }
    return result;
}