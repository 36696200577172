import "./job-preview.css";

let buttonNavigatorJob = document.getElementById('navigator-job');
if (buttonNavigatorJob) {
    buttonNavigatorJob.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        location.href = '/jobs/new' + location.search;
    });
}

let buttonNavigatorCheckout = document.getElementById('navigator-checkout');
if (buttonNavigatorCheckout) {
    buttonNavigatorCheckout.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        let form = document.createElement("form");
        form.setAttribute("action", '/jobs/checkout' + location.search);
        form.setAttribute("method", "post");
        form.style.display = "none";
        document.body.appendChild(form);
        let authenticityTokenElement = document.createElement('input');
        authenticityTokenElement.type = 'hidden';
        authenticityTokenElement.name = getMetaContent('csrf-param');
        authenticityTokenElement.value = getMetaContent('csrf-token');
        form.appendChild(authenticityTokenElement);
        form.submit();
    });
}

function getMetaContent(metaName) {
    let meta = document.head.children;
    let metaLength = meta.length;
    let result = null;
    for (let i = 0; i < metaLength; i++){
        let proper = meta[i].getAttribute('name');
        if(proper === metaName) {
            result = meta[i].getAttribute('content');
            break;
        }
    }
    return result;
}