import "./password-reset-edit.css";

let buttonPasswordReminderEditConfirm = document.getElementById('password-reminder-edit-confirm');
if (buttonPasswordReminderEditConfirm) {
    buttonPasswordReminderEditConfirm.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        let formPasswordReminderEdit = document.getElementById('password-reminder-edit-form');
        formPasswordReminderEdit.submit();
    });
}