import "./sign-up.css";

let buttonSignUpConfirm = document.getElementById('user-new-confirm');
if (buttonSignUpConfirm) {
    buttonSignUpConfirm.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        let formUserNew = document.getElementById('user-new-form');
        formUserNew.submit();
    });
}

let agreementCheckboxes = document.getElementsByClassName('agreement');
for (let i=0; i<agreementCheckboxes.length; i++){
    agreementCheckboxes[i].addEventListener('change', function(event) {
       let termOfServiceAgreement = document.getElementById('user_term_of_service_agreement');
       let privacyPolicyAgreement = document.getElementById('user_privacy_policy_agreement');
        let submitButton = document.getElementById('user-new-confirm');
       if (termOfServiceAgreement.checked && privacyPolicyAgreement.checked) {
           submitButton.classList.remove('sc-button-disabled');
           submitButton.classList.add('sc-button-primary');
           submitButton.disabled = false;
       } else {
           submitButton.classList.remove('sc-button-primary');
           submitButton.classList.add('sc-button-disabled');
           submitButton.disabled = true;
       }
    });
}