import "./password-reset.css";

let buttonPasswordReminderConfirm = document.getElementById('password-reminder-confirm');
if (buttonPasswordReminderConfirm) {
    buttonPasswordReminderConfirm.addEventListener('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        let formPasswordReminder = document.getElementById('password-reminder-form');
        formPasswordReminder.submit();
    });
}